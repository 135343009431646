<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">TEAM ATTENDANCE LIST</span>
          <v-flex xs12 align-self-center py-2>
            <v-layout wrap justify-start>
              <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-2 text-left>
                <span style="font-family: kumbhRegular; font-size: 14px"
                  >Pick a date to view the details</span
                >

                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormatted"
                      outlined
                      class="rounded-xl"
                      hide-details
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout
          wrap
          justify-start
          pa-0
          pa-xs-0
          pa-sm-2
          pa-md-5
          pa-lg-5
          pa-xl-5
        >
          <v-flex xs12>
            <v-card>
              <v-tabs background-color="#005f32" center-active dark fixed-tabs>
                <v-tab>Attendance List</v-tab>
                <v-tab>Unauthorized Leaves Section</v-tab>
                <v-tab>Employees late Section</v-tab>
                <v-tab>Authorized Leaves Section</v-tab>
                <v-tab-item>
                  <v-card flat>
                    <v-layout wrap justify-start py-5>
                      <v-flex xs12 sm6 md9 lg10 pl-3 text-left>
                        <span class="kumbhRegular">{{ msg1 }}</span>
                      </v-flex>
                      <v-flex xs12 sm6 md3 lg2 px-3 text-right
                        ><v-btn
                          style="
                            font-family: kumbhBold;
                            text-transform: capitalize;
                            font-size: 14px;
                          "
                          dark
                          block
                          class="rounded-xl"
                          color="#005f32"
                          @click="monthlyReport()"
                        >
                          Excel Download
                        </v-btn></v-flex
                      >
                    </v-layout>
                    <v-layout wrap v-if="teamAttendance.length > 0">
                      <v-flex xs12>
                        <v-simple-table class="align-center">
                          <template v-slot:default>
                            <thead style="background-color: #005f32">
                              <tr>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Employee ID
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Employee Name
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Punch In
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Punch Out
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody class="align-center">
                              <tr
                                class="text-left align-center"
                                v-for="(item, i) in teamAttendance"
                                :key="i"
                              >
                                <td>
                                  <span
                                    v-if="item.empID"
                                    style="font-family: kumbhMedium"
                                    >{{ item.empID }}</span
                                  >
                                </td>
                                <td>
                                  <span
                                    v-if="item.name"
                                    style="font-family: kumbhMedium"
                                    >{{ item.name }}</span
                                  >
                                </td>
                                <td>
                                  <span
                                    v-if="
                                      item.login && item.login != 'Not Marked'
                                    "
                                    style="font-family: kumbhMedium"
                                    >{{ convertUTCToLocal(item.login) }}</span
                                  >
                                  <span
                                    v-if="
                                      item.login && item.login == 'Not Marked'
                                    "
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ item.login }}</span
                                  >
                                </td>

                                <td>
                                  <span
                                    v-if="
                                      item.logout && item.logout != 'Not Marked'
                                    "
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ convertUTCToLocal(item.logout) }}</span
                                  >
                                  <span
                                    v-if="
                                      item.logout && item.logout == 'Not Marked'
                                    "
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ item.logout }}</span
                                  >
                                </td>
                                <td>
                                  <v-layout wrap>
                                    <v-flex
                                      xs12
                                      align-self-center
                                      text-left
                                      pa-2
                                    >
                                      <v-dialog
                                        v-model="item.view"
                                        max-width="70%"
                                        persistent
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-icon
                                            title="View"
                                            color="#005f32"
                                            v-on="on"
                                            @click="getView(item)"
                                            v-bind="attrs"
                                            >mdi-eye</v-icon
                                          >
                                        </template>
                                        <v-card>
                                          <v-layout wrap>
                                            <v-flex
                                              xs112
                                              align-self-center
                                              pa-4
                                              style="background: #005f32"
                                              text-left
                                            >
                                              <v-layout wrap>
                                                <v-flex xs11>
                                                  <span
                                                    class="kumbhBold"
                                                    style="color: #ffffff"
                                                    >ATTENDANCE DETAILS</span
                                                  >
                                                </v-flex>
                                                <v-flex xs1 text-right>
                                                  <v-icon
                                                    color="white"
                                                    @click="
                                                      (item.view = false),
                                                        clearData()
                                                    "
                                                    >mdi-close</v-icon
                                                  >
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-flex
                                              xs12
                                              align-self-center
                                              class="kumbhRegular"
                                            >
                                              <v-layout wrap pa-4>
                                                <v-flex
                                                  xs12
                                                  v-for="info in data"
                                                  :key="info._id"
                                                >
                                                  <v-timeline
                                                    :dense="
                                                      $vuetify.breakpoint
                                                        .smAndDown
                                                    "
                                                  >
                                                    <v-timeline-item
                                                      v-if="
                                                        info.attendanceType ==
                                                        'login'
                                                      "
                                                      color="#005f32"
                                                      fill-dot
                                                      right
                                                    >
                                                      <span
                                                        slot="opposite"
                                                        style="
                                                          font-family: kumbhMedium;
                                                          color: black;
                                                        "
                                                      >
                                                        Punch In
                                                        <br />
                                                        <br />
                                                        <span
                                                          style="
                                                            font-size: 16px;
                                                            font-family: kumbhSemibold;
                                                          "
                                                        >
                                                          {{
                                                            convertUTCToLocal(
                                                              info.attendanceTime
                                                            )
                                                          }}</span
                                                        ></span
                                                      >
                                                      <v-card>
                                                        <v-card-title
                                                          style="
                                                            background-color: #005f32;
                                                          "
                                                        >
                                                        </v-card-title>
                                                        <v-container>
                                                          <v-row>
                                                            <v-col
                                                              cols="12"
                                                              md="10"
                                                              class="text-left"
                                                            >
                                                              <span
                                                                v-if="
                                                                  info.address
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    font-family: kumbhSemibold;
                                                                  "
                                                                  >Address: </span
                                                                >{{
                                                                  info.address
                                                                }}
                                                                <br />
                                                              </span>
                                                              <span
                                                                v-if="info.city"
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    font-family: kumbhSemibold;
                                                                  "
                                                                  >City: </span
                                                                >{{ info.city }}
                                                                <br />
                                                              </span>
                                                              <span
                                                                v-if="
                                                                  info.state
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    font-family: kumbhSemibold;
                                                                  "
                                                                  >State: </span
                                                                >{{
                                                                  info.state
                                                                }}</span
                                                              >
                                                              <span
                                                                v-if="
                                                                  !info.address &&
                                                                  !info.city &&
                                                                  !info.state
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    font-family: kumbhSemibold;
                                                                  "
                                                                  >Address not
                                                                  fetched</span
                                                                ></span
                                                              >
                                                              <p
                                                                v-if="
                                                                  info.description !=
                                                                  ''
                                                                "
                                                                style="
                                                                  font-size: 16px;
                                                                  color: black !important;
                                                                "
                                                              >
                                                                <b
                                                                  >Description</b
                                                                >
                                                                <span>
                                                                  <br />
                                                                  &nbsp;  {{
                                                                    info.description.slice(
                                                                      0,
                                                                      100
                                                                    )
                                                                  }}
                                                                </span>
                                                              </p>

                                                              <div v-if="info.photos && info.photos.length">
                                                                <b>Images</b><br>
                                                                <v-chip
                                                                  v-for="(photo, index) in info.photos"
                                                                  :key="index"
                                                                  color="#b1fad9"
                                                                  class="ma-1"
                                                                >
                                                                  {{ photo }}
                                                                  &nbsp;<v-icon class="mt-1" small @click="openImageDialog(photo)">mdi-eye</v-icon>
                                                                </v-chip>
                                                              </div>
                                                            </v-col>
                                                          </v-row>
                                                        </v-container>
                                                      </v-card>
                                                    </v-timeline-item>
                                                    <v-timeline-item
                                                      v-if="
                                                        info.attendanceType ==
                                                        'other'
                                                      "
                                                      color="#005f32"
                                                      fill-dot
                                                      right
                                                    >
                                                      <span
                                                        slot="opposite"
                                                        style="
                                                          font-family: kumbhMedium;
                                                          color: black;
                                                        "
                                                      >
                                                        <br />
                                                        <br />
                                                        <span
                                                          style="
                                                            font-size: 16px;
                                                            font-family: kumbhSemibold;
                                                          "
                                                        >
                                                          {{
                                                            convertUTCToLocal(
                                                              info.attendanceTime
                                                            )
                                                          }}</span
                                                        ></span
                                                      >
                                                      <v-card>
                                                        <v-card-title
                                                          style="
                                                            background-color: #005f32;
                                                          "
                                                        >
                                                        </v-card-title>
                                                        <v-container>
                                                          <v-row>
                                                            <v-col
                                                              cols="12"
                                                              md="10"
                                                              class="text-left"
                                                            >
                                                              <span
                                                                v-if="
                                                                  info.address
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    font-family: kumbhSemibold;
                                                                  "
                                                                  >Address: </span
                                                                >{{
                                                                  info.address
                                                                }}</span
                                                              >
                                                              <br />
                                                              <span
                                                                v-if="info.city"
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    font-family: kumbhSemibold;
                                                                  "
                                                                  >City: </span
                                                                >{{
                                                                  info.city
                                                                }}</span
                                                              >
                                                              <br />
                                                              <span
                                                                v-if="
                                                                  info.state
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    font-family: kumbhSemibold;
                                                                  "
                                                                  >State: </span
                                                                >{{
                                                                  info.state
                                                                }}</span
                                                              >
                                                              <span
                                                                v-if="
                                                                  !info.address &&
                                                                  !info.city &&
                                                                  !info.state
                                                                "
                                                              >
                                                                <span
                                                                  style="
                                                                    font-size: 15px;
                                                                    font-family: kumbhSemibold;
                                                                  "
                                                                  >Address not
                                                                  fetched</span
                                                                ></span
                                                              >
                                                              <p
                                                                v-if="
                                                                  info.description !=
                                                                  ''
                                                                "
                                                                style="
                                                                  font-size: 16px;
                                                                  color: black !important;
                                                                "
                                                              >
                                                                <b
                                                                  >Description</b
                                                                >
                                                                <span>
                                                                  <br />
                                                                  {{
                                                                    info.description.slice(
                                                                      0,
                                                                      100
                                                                    )
                                                                  }}
                                                                </span>
                                                              </p>
                                                            </v-col>
                                                          </v-row>
                                                        </v-container>
                                                      </v-card>
                                                    </v-timeline-item>
                                                    <v-timeline-item
                                                      v-if="
                                                        info.attendanceType ==
                                                        'logout'
                                                      "
                                                      color="#005f32"
                                                      fill-dot
                                                      left
                                                    >
                                                      <span
                                                        slot="opposite"
                                                        style="
                                                          font-family: kumbhMedium;
                                                          color: black;
                                                        "
                                                      >
                                                        <span
                                                          v-if="
                                                            info.applyMode ==
                                                            'auto'
                                                          "
                                                          >Auto Punchout</span
                                                        >
                                                        <span v-else>
                                                          Punch Out</span
                                                        >
                                                        <br />
                                                        <br />
                                                        <span
                                                          style="
                                                            font-size: 16px;
                                                            font-family: kumbhSemibold;
                                                          "
                                                        >
                                                          {{
                                                            convertUTCToLocal(
                                                              info.attendanceTime
                                                            )
                                                          }}</span
                                                        ></span
                                                      >
                                                      <v-card>
                                                        <v-card-title
                                                          style="
                                                            background-color: #005f32;
                                                          "
                                                        >
                                                        </v-card-title>
                                                        <v-container>
                                                          <v-row>
                                                            <v-col
                                                              cols="12"
                                                              md="10"
                                                              class="text-left"
                                                            >
                                                              <span
                                                                v-if="
                                                                  info.address
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    font-family: kumbhSemibold;
                                                                  "
                                                                  >Address: </span
                                                                >{{
                                                                  info.address
                                                                }}
                                                                <br />
                                                              </span>
                                                              <span
                                                                v-if="info.city"
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    font-family: kumbhSemibold;
                                                                  "
                                                                  >City: </span
                                                                >{{ info.city }}
                                                                <br />
                                                              </span>
                                                              <span
                                                                v-if="
                                                                  info.state
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    font-family: kumbhSemibold;
                                                                  "
                                                                  >State: </span
                                                                >{{
                                                                  info.state
                                                                }}</span
                                                              >
                                                              <span
                                                                v-if="
                                                                  !info.address &&
                                                                  !info.city &&
                                                                  !info.state
                                                                "
                                                              >
                                                                <span
                                                                  style="
                                                                    font-size: 15px;
                                                                    font-family: kumbhSemibold;
                                                                  "
                                                                  >Address not
                                                                  fetched</span
                                                                ></span
                                                              >
                                                              <p
                                                                v-if="
                                                                  info.description !=
                                                                  ''
                                                                "
                                                                style="
                                                                  font-size: 16px;
                                                                  color: black !important;
                                                                "
                                                              >
                                                                <b
                                                                  >Description</b
                                                                >
                                                                <span>
                                                                  <br />
                                                                  {{
                                                                    info.description.slice(
                                                                      0,
                                                                      100
                                                                    )
                                                                  }}
                                                                </span>
                                                              </p>
                                                            </v-col>
                                                          </v-row>
                                                        </v-container>
                                                      </v-card>
                                                    </v-timeline-item>
                                                  </v-timeline>
                                                </v-flex>
                                                <v-flex xs12 text-left pt-10>
                                                  <div class="listing-section">
                                                    <span
                                                      class="listing-desc-headline margin-top-60 poppinssemibold"
                                                      style="
                                                        font-size: 20px;
                                                        margin-bottom: 30px;
                                                        font-family: kumbhMedium;
                                                      "
                                                      >Location</span
                                                    >
                                                    <GmapMap
                                                      :center="mapCenter"
                                                      :zoom="3"
                                                      style="
                                                        width: 100%;
                                                        height: 400px;
                                                      "
                                                    >
                                                      <GmapMarker
                                                        v-for="(
                                                          location, index
                                                        ) in locations"
                                                        :key="index"
                                                        :position="
                                                          location.position
                                                        "
                                                        :icon="
                                                          getMarkerIcon(
                                                            location
                                                          )
                                                        "
                                                        @click="
                                                          showInfoWindow(
                                                            location
                                                          )
                                                        "
                                                      />
                                                      <GmapInfoWindow
                                                        :options="{
                                                          pixelOffset: {
                                                            width: 0,
                                                            height: -35,
                                                          },
                                                        }"
                                                        :position="
                                                          infoWindow.position
                                                        "
                                                        :opened="
                                                          infoWindow.open
                                                        "
                                                        @closeclick="
                                                          infoWindow.open = false
                                                        "
                                                      >
                                                        <div
                                                          v-html="
                                                            infoWindow.content
                                                          "
                                                        ></div>
                                                      </GmapInfoWindow>
                                                    </GmapMap>
                                                  </div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </v-card>
                                      </v-dialog>
                                    </v-flex>
                                  </v-layout>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap v-else>
                      <v-flex xs12 align-self-center pa-16>
                        <span class="kumbhRegular">No Data found!</span>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-layout wrap justify-start py-5>
                      <v-flex xs12 sm6 md9 lg10 pl-3 text-left>
                        <span class="kumbhRegular">{{ msg2 }} </span>
                      </v-flex>
                      <v-flex xs12 sm6 md3 lg2 px-3 text-right
                        ><v-btn
                          style="
                            font-family: kumbhBold;
                            text-transform: capitalize;
                            font-size: 14px;
                          "
                          dark
                          block
                          class="rounded-xl"
                          color="#005f32"
                          @click="reportAbset()"
                        >
                          Excel Download
                        </v-btn></v-flex
                      >
                    </v-layout>
                    <v-layout wrap v-if="abset.length > 0">
                      <v-flex xs12>
                        <v-simple-table class="align-center">
                          <template v-slot:default>
                            <thead style="background-color: #005f32">
                              <tr>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Employee ID
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Employee Name
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Email ID
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody class="align-center">
                              <tr
                                class="text-left align-center"
                                v-for="(ab, a) in abset"
                                :key="a"
                              >
                                <td>
                                  <span
                                    v-if="ab.empID"
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ ab.empID.slice(0, 10) }}</span
                                  >
                                </td>
                                <td>
                                  <span
                                    v-if="ab.name"
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ ab.name }}</span
                                  >
                                </td>
                                <td>
                                  <span
                                    v-if="ab.email"
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ ab.email }}</span
                                  >
                                </td>

                                <td>
                                  <span
                                    v-if="ab.status"
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ ab.status }}</span
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap v-else>
                      <v-flex xs12 align-self-center pa-16>
                        <span class="kumbhRegular">No Data found!</span>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-layout wrap justify-start py-5>
                      <v-flex xs12 sm6 md9 lg10 pl-3 text-left>
                        <span class="kumbhRegular">{{ msg3 }}</span>
                      </v-flex>
                      <v-flex xs12 sm6 md3 lg2 px-3 text-right
                        ><v-btn
                          style="
                            font-family: kumbhBold;
                            text-transform: capitalize;
                            font-size: 14px;
                          "
                          dark
                          block
                          class="rounded-xl"
                          color="#005f32"
                          @click="reportLate()"
                        >
                          Excel Download
                        </v-btn></v-flex
                      >
                    </v-layout>
                    <v-layout wrap v-if="late.length > 0">
                      <v-flex xs12>
                        <v-simple-table class="align-center">
                          <template v-slot:default>
                            <thead style="background-color: #005f32">
                              <tr>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Employee ID
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Employee Name
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Punch In
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Late By
                                </th>
                              </tr>
                            </thead>
                            <tbody class="align-center">
                              <tr
                                class="text-left align-center"
                                v-for="(la, l) in late"
                                :key="l"
                              >
                                <td>
                                  <span
                                    v-if="la.empID"
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ la.empID.slice(0, 10) }}</span
                                  >
                                </td>
                                <td>
                                  <span
                                    v-if="la.name"
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ la.name }}</span
                                  >
                                </td>
                                <td>
                                  <span
                                    v-if="la.login"
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ convertUTCToLocal(la.login) }}</span
                                  >
                                </td>

                                <td>
                                  <span
                                    v-if="la.lateBy"
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ la.lateBy }} hr</span
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap v-else>
                      <v-flex xs12 align-self-center pa-16>
                        <span class="kumbhRegular">No Data found!</span>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-layout wrap justify-start py-5>
                      <v-flex xs12 sm6 md9 lg10 pl-3 text-left>
                        <span class="kumbhRegular">{{ msg4 }}</span>
                      </v-flex>
                      <v-flex xs12 sm6 md3 lg2 px-3 text-right
                        ><v-btn
                          style="
                            font-family: kumbhBold;
                            text-transform: capitalize;
                            font-size: 14px;
                          "
                          dark
                          block
                          class="rounded-xl"
                          color="#005f32"
                          @click="reportAuthorized()"
                        >
                          Excel Download
                        </v-btn></v-flex
                      >
                    </v-layout>
                    <v-layout wrap v-if="authorized.length > 0">
                      <v-flex xs12>
                        <v-simple-table class="align-center">
                          <template v-slot:default>
                            <thead style="background-color: #005f32">
                              <tr>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Employee ID
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Employee Name
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Email ID
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody class="align-center">
                              <tr
                                class="text-left align-center"
                                v-for="(auth, au) in authorized"
                                :key="au"
                              >
                                <td>
                                  <span
                                    v-if="auth.empID"
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ auth.empID.slice(0, 10) }}</span
                                  >
                                </td>
                                <td>
                                  <span
                                    v-if="auth.name"
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ auth.name }}</span
                                  >
                                </td>
                                <td>
                                  <span
                                    v-if="auth.email"
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ auth.email }}</span
                                  >
                                </td>

                                <td>
                                  <span
                                    v-if="auth.status"
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ auth.status }}</span
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap v-else>
                      <v-flex xs12 align-self-center pa-16>
                        <span class="kumbhRegular">No Data found!</span>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <!-- <v-layout wrap justify-center>
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout> -->
    <v-dialog persistent v-model="viewImagedialog" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">

            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">View Image</span>
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="viewImagedialog = false">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pt-2>
            <v-layout wrap px-5 pb-8 justify-center>
              <v-flex xs12 align-self-center text-center>
                
                 <v-img :src="mediaURL + selectedPhoto" fill-height height="60%" width="60%"/>
                </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<!-- <script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyDPNXCjI6IJbDqwJcJwGuJUfNWxfMLSe-U&libraries=visualization,drawing"></script> -->
<script>
// import { GmapMap, GmapMarker, GmapInfoWindow } from 'vue2-google-maps'
import axios from "axios";
// var marker;
// var marker2;
export default {
  // components: {
  //   GmapMap,
  //   GmapMarker,
  //   GmapInfoWindow,
  // },
  data: (vm) => ({
    viewImagedialog: false,
    selectedPhoto: '',
    showsnackbar: false,
    ServerError: false,
    msg: null,
    pages: 0,
    dialog: false,
    pageCount: 1,
    appLoading: false,
    editedIndex: -1,
    currentPage: 1,
    g: false,
    totalData: 0,
    addcat: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    teamAttendance: [],
    data: [],
    name: null,
    assetId: null,
    date: new Date().toISOString(),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 7)),
    menu1: false,
    menu2: false,
    abset: [],
    authorized: [],
    late: [],
    msg1: "",
    msg2: "",
    msg3: "",
    msg4: "",
    loginData: "",
    logoutData: "",
    place: "",
    locations: [],
    mapCenter: {},
    infoWindow: {
      open: false,
      position: null,
      content: "",
    },
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  mounted() {
    this.getData();
    this.getAbset();
    this.getAuthorized();
    this.getLate();
  },
  watch: {
    date() {
      this.currentPage = 1;
      this.dateFormatted = this.formatDate(this.date);
      if (this.computedDateFormatted) {
        this.getData();
        this.getAbset();
        this.getAuthorized();
        this.getLate();
      }
    },
    // currentPage() {
    //   this.getData();
    // },
  },

  methods: {
    openImageDialog(photo) {
      this.selectedPhoto = photo; // Set the clicked photo
      this.viewImagedialog = true; // Open the dialog
    },
    getData() {
      let dateStr = this.computedDateFormatted;
      // Convert to Date object
      let [day, month, year] = dateStr.split("-");
      let dateObj = new Date(`${year}-${month}-${day}`);

      // Format the date
      let formattedDateStr = dateObj.toISOString().split("T")[0];
      this.appLoading = true;
      axios({
        url: "/employee/attendance/all/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          // page: this.currentPage,
          // limit: 20,
          date: formattedDateStr,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.teamAttendance = response.data.data;
          this.msg1 = response.data.msg;
          this.totalData = response.data.length;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    monthlyReport() {
      let dateStr = this.computedDateFormatted;
      // Convert to Date object
      let [day, month, year] = dateStr.split("-");
      let dateObj = new Date(`${year}-${month}-${day}`);

      // Format the date
      let formattedDateStr = dateObj.toISOString().split("T")[0];
      axios({
        method: "POST",
        url: "/attendance/employees/datewise/list/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          // date: this.date,
          date:formattedDateStr
        },
        responseType: "blob", // Specify the response type as blob
      })
        .then((response) => {
          // Ensure the correct MIME type for Excel files
          const contentType =
            response.headers["content-type"] ||
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([response.data], { type: contentType });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank"; // Open in a new tab
          anchor.download = "Employees Attendance Report.xlsx"; // Set the file name

          // Programmatically click on the anchor to download the file
          anchor.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    reportAbset(){
      let dateStr = this.computedDateFormatted;
      // Convert to Date object
      let [day, month, year] = dateStr.split("-");
      let dateObj = new Date(`${year}-${month}-${day}`);

      // Format the date
      let formattedDateStr = dateObj.toISOString().split("T")[0];
      axios({
        method: "POST",
        url: "/employee/attendance/absent/list/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          // date: this.date,
          date:formattedDateStr
        },
        responseType: "blob", // Specify the response type as blob
      })
        .then((response) => {
          // Ensure the correct MIME type for Excel files
          const contentType =
            response.headers["content-type"] ||
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([response.data], { type: contentType });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank"; // Open in a new tab
          anchor.download = "Employees Unauthorized Leave Report.xlsx"; // Set the file name

          // Programmatically click on the anchor to download the file
          anchor.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    reportLate(){
      let dateStr = this.computedDateFormatted;
      // Convert to Date object
      let [day, month, year] = dateStr.split("-");
      let dateObj = new Date(`${year}-${month}-${day}`);

      // Format the date
      let formattedDateStr = dateObj.toISOString().split("T")[0];
      axios({
        method: "POST",
        url: "/employee/attendance/late/list/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          // date: this.date,
          date:formattedDateStr
        },
        responseType: "blob", // Specify the response type as blob
      })
        .then((response) => {
          // Ensure the correct MIME type for Excel files
          const contentType =
            response.headers["content-type"] ||
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([response.data], { type: contentType });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank"; // Open in a new tab
          anchor.download = "Employees Late Section.xlsx"; // Set the file name

          // Programmatically click on the anchor to download the file
          anchor.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    reportAuthorized(){
      let dateStr = this.computedDateFormatted;
      // Convert to Date object
      let [day, month, year] = dateStr.split("-");
      let dateObj = new Date(`${year}-${month}-${day}`);

      // Format the date
      let formattedDateStr = dateObj.toISOString().split("T")[0];
      axios({
        method: "POST",
        url: "/employee/attendance/authorized/leaves/list/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          // date: this.date,
          date:formattedDateStr
        },
        responseType: "blob", // Specify the response type as blob
      })
        .then((response) => {
          // Ensure the correct MIME type for Excel files
          const contentType =
            response.headers["content-type"] ||
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([response.data], { type: contentType });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank"; // Open in a new tab
          anchor.download = "Employees Authorized Leaves.xlsx"; // Set the file name

          // Programmatically click on the anchor to download the file
          anchor.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getAbset() {
      let dateStr = this.computedDateFormatted;
      // Convert to Date object
      let [day, month, year] = dateStr.split("-");
      let dateObj = new Date(`${year}-${month}-${day}`);

      // Format the date
      let formattedDateStr = dateObj.toISOString().split("T")[0];
      this.appLoading = true;
      axios({
        url: "/employee/attendance/absent/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          // page: this.currentPage,
          // limit: 20,
          // date: this.date,
          date:formattedDateStr
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.abset = response.data.data;
          this.msg2 = response.data.msg;
          this.totalData = response.data.length;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getAuthorized() {
      let dateStr = this.computedDateFormatted;
      // Convert to Date object
      let [day, month, year] = dateStr.split("-");
      let dateObj = new Date(`${year}-${month}-${day}`);
      // Format the date
      let formattedDateStr = dateObj.toISOString().split("T")[0];
      this.appLoading = true;
      axios({
        url: "/employee/attendance/authorized/leaves/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          // page: this.currentPage,
          // limit: 20,
          // date: this.date,
          date:formattedDateStr
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.authorized = response.data.data;
          this.msg4 = response.data.msg;
          this.totalData = response.data.length;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getLate() {
      let dateStr = this.computedDateFormatted;
      // Convert to Date object
      let [day, month, year] = dateStr.split("-");
      let dateObj = new Date(`${year}-${month}-${day}`);
      // Format the date
      let formattedDateStr = dateObj.toISOString().split("T")[0];
      this.appLoading = true;
      axios({
        url: "/employee/attendance/late/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          // page: this.currentPage,
          // limit: 20,
          // date: this.date,
          date:formattedDateStr
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.late = response.data.data;
          this.msg3 = response.data.msg;
          this.totalData = response.data.length;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getView(item) {
      axios({
        url: "/employee/attendance/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: item.uid,
          date: this.date,
        },
      })
        .then((response) => {
          this.appLoading = false;
          (this.locations = []), (this.data = response.data.data);
          const transformedData = response.data.data.map((item) => ({
            type: item.attendanceType,
            position: { lat: item.location[1], lng: item.location[0] },
            time: item.attendanceTime,
            address: item.address,
            city: item.city,
            state: item.state,
            date: item.attendanceTime,
            employee: item.uid.name,
          }));
          this.locations = transformedData;
          if (this.locations.length > 0) {
            this.mapCenter = this.locations[0].position;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    showInfoWindow(location) {
      this.infoWindow.position = location.position;
      if (
        location.address == undefined &&
        location.city == undefined &&
        location.state == undefined
      ) {
        this.place = "Address not fetched";
      } else {
        this.place =
          location.address + " " + location.city + " " + location.state;
      }
      var type = "";
      if (location.type == "login") type = "Punch In";
      else type = "Punch Out";
      this.infoWindow.content = `
        <div>
          <h4>${type}</h4>
         </br>
          <span>Employee: ${location.employee}</span>
          </br>
           <span>Date: ${location.date.slice(0, 10)}</span></br>
          <span>Time: ${this.convertUTCToLocal(location.time)}</span></br>
          <span>Address: ${this.place}</span>
        </div>
      `;
      this.infoWindow.open = true;
    },
    getMarkerIcon(location) {
      // Define your logic for choosing different colored icons
      if (location.type === "login") {
        return "http://maps.google.com/mapfiles/ms/icons/green-dot.png"; // URL to your custom marker icon
      } else {
        return "http://maps.google.com/mapfiles/ms/icons/red-dot.png"; // URL to your default marker icon
      }
    },
    clearData() {
      this.locations = [];
    },
    convertUTCToLocal(utcDateString) {
      var utcDate = new Date(utcDateString);
      const hours = utcDate.getHours();
      const minutes = utcDate.getMinutes().toString().padStart(2, "0");
      const seconds = utcDate.getSeconds().toString().padStart(2, "0");
      //   const amPm = hours >= 12 ? "PM" : "AM";

      // Adjust hours for 12-hour format (convert from 24-hour to 12-hour)
      const adjustedHours = hours % 12 || 12;

      // Format the time string (HH:MM AM/PM)
      const formattedTime = `${adjustedHours}:${minutes}:${seconds}`;

      // Return the date and time as separate objects
      return formattedTime;
      // return {
      //   date: formattedDate,
      //   time: formattedTime
      // };
    },
    formatDate(date) {
      var dt = new Date(date);
      var day = String(dt.getDate()).padStart(2, "0"); // Ensure two digits
      var month = String(dt.getMonth() + 1).padStart(2, "0"); // Ensure two digits
      var year = dt.getFullYear();
      var strTime = day + "-" + month + "-" + year;
      return strTime;
    },
  },
};
</script>
<style>
#myMap {
  height: 400px !important;
  width: 100%;
  border: 2px solid red;
}
</style>